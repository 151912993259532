import React from 'react';
import { useRecoilCallback } from 'recoil';

import { Button } from '~/components/blocks';
import { requestAppointmentModalState } from '~/state/reception/atoms';

export const RequestBookingButton = () => {
  const handleClick = useRecoilCallback(
    ({ set }) =>
      () => {
        set(requestAppointmentModalState, {
          isOpen: true,
        });
      },
    [],
  );

  return (
    <Button use="primary" wide="fill" size="l" onClick={handleClick}>
      FAX等で処方箋を受領した
    </Button>
  );
};
