import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { useRecoilCallback } from 'recoil';

import { Flex, Icon } from '~/components/blocks';
import { finishDraftAppointmentModalState } from '~/state/reception/atoms';

const Link = styled('a')(({ theme }) =>
  css({
    textDecoration: 'underline',
    fontSize: theme.fontSizes.s,
    color: theme.colors.text.default,
    marginLeft: theme.space.xs,
    ':hover': {
      textDecoration: 'none',
      cursor: 'pointer',
    },
  }),
);

export const FinishButton = () => {
  const handleClick = useRecoilCallback(
    ({ set }) =>
      () => {
        set(finishDraftAppointmentModalState, {
          isOpen: true,
        });
      },
    [],
  );

  return (
    <Flex justifyContent="center" alignItems="center">
      <Icon icon="hint" size="m" color="navy" />
      <Link onClick={handleClick}>患者が来局した場合</Link>
    </Flex>
  );
};
