import { ApolloError } from '@apollo/client';
import { useTheme } from '@emotion/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { Alert, Button, Modal, Text } from '~/components/blocks';
import { finishDraftAppointmentModalState } from '~/state/reception/atoms';

import { useFinishDraftAppointment } from './use-finish-draft-appointment';

type Props = {
  draftAppointmentId: string;
};

export const FinishDraftAppointmentModal = (props: Props) => {
  const theme = useTheme();
  const [state, setState] = useRecoilState(finishDraftAppointmentModalState);
  const [error, setError] = useState<ApolloError | undefined>(undefined);
  const handleClose = useCallback(() => setState(() => ({ isOpen: false })), [setState]);

  const listStyle = {
    listStyle: 'outside',
    fontSize: theme.fontSizes.s,
    marginLeft: theme.space.l,
  };

  const {
    loading,
    error: finishError,
    handleFinish,
  } = useFinishDraftAppointment({
    draftAppointmentId: props.draftAppointmentId,
  });

  useEffect(() => {
    if (!state.isOpen) {
      setError(undefined);
    }
  }, [state.isOpen]);

  useEffect(() => {
    setError(finishError);
  }, [finishError]);

  return (
    <Modal open={state.isOpen} size="s" onClose={handleClose}>
      <Modal.Header>
        <Text size="l">患者が来局した場合</Text>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert mb={theme.space.l}>エラーが発生したため完了できませんでした</Alert>}
        <Text block>患者が来局した場合は、以下をご確認の上、対面で服薬指導を行ってください</Text>
        <Alert status="warning" marginTop={theme.space.m}>
          <ul style={listStyle}>
            <li>アプリ決済での会計はできません。店頭レジにてご対応ください</li>
            <li>
              下記のボタンを押して完了すると、オンラインでの服薬指導はできなくなります。また、当日配達
              <Text size="xs">（Uber Eats）</Text>をご希望の場合、キャンセルとなります
            </li>
          </ul>
        </Alert>
      </Modal.Body>
      <Modal.Footer>
        <Button use="default" marginRight={theme.space.m} onClick={handleClose}>
          閉じる
        </Button>
        <Button use="secondary" loading={loading} onClick={handleFinish}>
          対面で服薬指導を完了した
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
