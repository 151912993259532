import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { Formik, FormikProps } from 'formik';
import React, { CSSProperties, useEffect, useRef } from 'react';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { Box, Button, CloseBtn, Header, Radio, RadioGroup } from '~/components/blocks';
import { CancelAlert } from '~/components/partials/CancelAlert';
import { DraftAppointmentOnFooterFragment, DraftAppointmentStatus } from '~/graphql';
import { cancelDraftAppointmentPane } from '~/state/reception/atoms';
import { cancellationReasons, translateReason } from '~/utils/draft_appointments';

import { Fields } from './types';
import { useCancelDraftAppointment } from './use-cancel-draft-appointment';
import { useDefaultValues } from './use-default_values';
import { validationSchema } from './validation';

type Props = { draftAppointment: DraftAppointmentOnFooterFragment };

const Root = styled('div', {
  shouldForwardProp,
})(({ theme }) =>
  css({
    position: 'absolute',
    bottom: 0,
    marginX: theme.space.l,
    transform: 'translateY(100%)',
    transition: `transform ${theme.transitions.default} ease-in-out`,
    zIndex: 2,
    right: 0,
    left: 0,
  }),
);

const Content = styled('div')(({ theme }) =>
  css({
    position: 'relative',
    margin: '0 auto',
    padding: theme.space.l,
    background: theme.colors.background.default,
    border: theme.borders.default,
    borderBottom: 0,
    borderTopLeftRadius: theme.radii.default,
    borderTopRightRadius: theme.radii.default,
    boxShadow: `0 2px 8px rgba(0,0,0, 0.3)`,
  }),
);

const Close = styled(CloseBtn)(({ theme }) =>
  css({
    position: 'absolute',
    top: theme.space.l,
    right: theme.space.l,
  }),
);

const transitionStyles: Partial<Record<TransitionStatus, CSSProperties>> = {
  entering: { transform: 'translateY(100%)' },
  entered: { transform: 'translateY(0)' },
  exiting: { transform: 'translateY(0)' },
  exited: { transform: 'translateY(100%)' },
};

export const CancelPane = React.memo((props: Props) => {
  const { draftAppointment } = props;
  const { handleCancel, isLoading } = useCancelDraftAppointment(draftAppointment.id);
  const theme = useTheme();
  const formikRef = useRef<FormikProps<Fields>>(null);
  const defaultValues = useDefaultValues();
  const { isOpen } = useRecoilValue(cancelDraftAppointmentPane);
  const handleClose = useRecoilCallback(
    ({ reset }) =>
      () =>
        reset(cancelDraftAppointmentPane),
    [],
  );
  const handleSubmit = useRecoilCallback(
    ({ reset }) =>
      async ({ cancelReason }: Fields) => {
        if (!cancelReason) {
          return;
        }

        await handleCancel(cancelReason);
        reset(cancelDraftAppointmentPane);
      },
    [handleCancel],
  );

  useEffect(() => {
    if (!isOpen) {
      formikRef.current?.resetForm();
    }
  }, [isOpen]);

  const status = draftAppointment.status;
  const sendMethod = draftAppointment.pfDispensingRequest?.sendMethod;

  if (!(status === 'available' || status === 'wait_for_booking')) {
    return null;
  }

  return (
    <Transition in={isOpen} timeout={0}>
      {(state) => (
        <Root
          style={{
            ...transitionStyles[state],
          }}
        >
          <Content>
            <Header>キャンセルしますか？</Header>
            {(status === 'available' || status === 'wait_for_booking') && (
              <CancelAlert
                draftAppointmentId={draftAppointment.id}
                prescriptionSendMethod={sendMethod}
              />
            )}
            <Formik
              innerRef={formikRef}
              initialValues={defaultValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <>
                  <Box mt={theme.space.l}>
                    <RadioGroup orientation="vertical">
                      {cancellationReasons[status].map((reason, index) => (
                        <Radio
                          key={index}
                          name="cancelReason"
                          label={
                            translateReason({
                              status: DraftAppointmentStatus.Cancelled,
                              cancellationReason: reason,
                            }).detail
                          }
                          value={reason}
                          checked={formik.values.cancelReason === reason}
                          onChange={formik.handleChange}
                        />
                      ))}
                    </RadioGroup>
                  </Box>
                  <Box mt={theme.space.xl}>
                    <Button
                      wide="fill"
                      use="secondary"
                      disabled={!formik.values.cancelReason}
                      loading={isLoading}
                      onClick={formik.submitForm}
                    >
                      キャンセル
                    </Button>
                  </Box>
                </>
              )}
            </Formik>
            <Close onClick={handleClose} />
          </Content>
        </Root>
      )}
    </Transition>
  );
});

CancelPane.displayName = 'CancelPane';
