import { useTheme } from '@emotion/react';
import React, { useEffect } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { Alert, Drawer, Flex } from '~/components/blocks';
import { DraftAppointmentOnFooterFragment } from '~/graphql';
import {
  cancelDraftAppointmentPane,
  draftAppointmentPatientProfileDrawerState,
} from '~/state/reception/atoms';

import { CancelPane } from '../CancelPane';
import { CancelButton } from './CancelButton';
import { FinishButton } from './FinishButton';
import { RequestBookingButton } from './RequestBookingButton';
import { useFetchDraftAppointment } from './use-fetch-draft-appointment';

type Props = {
  draftAppointmentId: string;
};

const FooterParent = (
  props: React.PropsWithChildren<{ draftAppointment: DraftAppointmentOnFooterFragment }>,
) => {
  const state = useRecoilValue(draftAppointmentPatientProfileDrawerState);
  const theme = useTheme();

  return (
    <>
      <Drawer.Footer>
        {state.error && (
          <Alert status="error" marginBottom={theme.space.l}>
            {state.error}
          </Alert>
        )}
        <Flex flexDirection="column" justifyContent="center">
          {props.children}
        </Flex>
      </Drawer.Footer>
      <CancelPane draftAppointment={props.draftAppointment} />
    </>
  );
};

export const Footer = React.memo((props: Props) => {
  const { draftAppointment } = useFetchDraftAppointment(props.draftAppointmentId);
  const resetCancelPane = useResetRecoilState(cancelDraftAppointmentPane);

  useEffect(() => {
    resetCancelPane();
  }, [resetCancelPane]);

  if (!draftAppointment) {
    return null;
  }

  if (draftAppointment.status === 'available') {
    return (
      <FooterParent draftAppointment={draftAppointment}>
        <RequestBookingButton />
        <CancelButton />
        <FinishButton />
      </FooterParent>
    );
  }

  if (draftAppointment.status === 'wait_for_booking') {
    return (
      <FooterParent draftAppointment={draftAppointment}>
        <CancelButton />
        <FinishButton />
      </FooterParent>
    );
  }

  return null;
});

Footer.displayName = 'Footer';
