import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { FormikProps } from 'formik';
import React from 'react';
import { useRecoilCallback } from 'recoil';

import { Alert, Box, Button, Modal, Select, Text } from '~/components/blocks';
import { TextSpan } from '~/components/blocks/TextSpan';
import { Interview } from '~/components/partials';
import { requestUberDeliveryModalState } from '~/state/reception/atoms';
import { publicPath } from '~/utils/path';

import { CustomQuestionTitle } from './CustomQuestionTitle';
import { Fields, SelectQuantities } from './type';

type Props = {
  formik: FormikProps<Fields>;
};

const CustomAlert = styled(Alert)(({ theme }) =>
  css({
    fontSize: theme.fontSizes.s,
    padding: theme.space.m,
    '& ol': {
      paddingLeft: '1.4em',
    },
    '& ol ::marker': {
      fontWeight: 'bold',
    },
    '& li': {
      listStyleType: 'decimal',
      listStylePosition: 'inside',
      textIndent: '-1.2em',
    },
  }),
);

const HelpLink = styled('a')(({ theme }) =>
  css({
    color: theme.colors.text.link,
    ':hover': {
      textDecoration: 'none',
    },
  }),
);

const CustomContents = styled(Interview.Contents)(() =>
  css({
    paddingLeft: '0px',
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
  }),
);

const Image = styled('img')(({ theme }) =>
  css({
    display: 'block',
    minWidth: '360px',
    margin: `calc(${theme.space.m} + ${theme.space.s})`,
    flex: 1,
  }),
);

const CustomSelect = styled(Select)(() =>
  css({
    flex: 1,
  }),
);

export const SelectQuantityContent = React.memo((props: Props) => {
  const { formik } = props;
  const theme = useTheme();

  const handleNext = useRecoilCallback(
    ({ set }) =>
      async () => {
        await formik.setValues({
          ...formik.values,
          pieces: Array.from({ length: formik.values.quantity }).map<number>(() => 1),
        });
        set(requestUberDeliveryModalState, (_state) => ({
          ..._state,
          step: 'inputPieces' as const,
        }));
      },
    [formik],
  );

  return (
    <Box>
      <Modal.Body minHeight="360px">
        <CustomAlert status="info">
          <ol>
            <li>
              <b>お薬の梱包準備をお願いします</b>
              <br />
              梱包方法の詳細については、
              <HelpLink
                target="_blank"
                rel="noopener noreferrer"
                href="https://clinics.my.salesforce.com/sfc/p/#28000001G064/a/2j000008uu5Q/vs6n9PaUwqpWv06MjExGpxIEQDZV_J0hhbgt_9Rxvdc"
              >
                当日配達
                <TextSpan size="xs" text="（Uber&nbsp;Eats）" />
                の開始チェックリスト
              </HelpLink>
              をご確認ください
            </li>
            <li>
              <b>梱包した荷物に指定の資材を貼り付けてください</b>
              <br />
              <HelpLink
                target="_blank"
                rel="noopener noreferrer"
                href="https://intercom.help/pharms/ja/articles/8965484-%E5%BD%93%E6%97%A5%E9%85%8D%E9%81%94%E3%82%B5%E3%83%BC%E3%83%93%E3%82%B9%E5%88%A9%E7%94%A8%E6%99%82%E3%81%AE%E8%96%AC%E5%B1%80%E5%90%91%E3%81%91%E8%B3%87%E6%9D%90#:~:text=%E2%97%BC%EF%B8%8F%E3%80%90%E6%82%A3%E8%80%85%E6%A7%98%E5%90%91%E3%81%91%E3%80%91%E5%87%A6%E6%96%B9%E7%AE%8B%E8%96%AC%E9%85%8D%E9%81%94%E6%99%82%E3%81%AE%E3%81%94%E6%A1%88%E5%86%85%E8%B3%87%E6%9D%90"
              >
                ご案内資材
              </HelpLink>
              からダウンロード・印刷してご対応ください
            </li>
          </ol>
        </CustomAlert>
        <Box>
          <Text marginY={theme.space.l} size="s">
            質問に答えて集荷依頼を完了してください。
          </Text>
          <Interview>
            <CustomQuestionTitle>
              <Text fontWeight="bold">配達員に渡す「荷物」はいくつありますか？</Text>
            </CustomQuestionTitle>
            <CustomContents>
              <Image
                alt="当日配達荷物イメージ"
                src={publicPath('/img/same_day_delivery_quantity.png')}
              />
              <CustomSelect
                name="quantity"
                error={formik.errors.quantity}
                value={formik.values.quantity}
                onChange={formik.handleChange}
                fill
              >
                {SelectQuantities.map((number) => (
                  <option key={number} value={number}>
                    {number}
                  </option>
                ))}
              </CustomSelect>
            </CustomContents>
          </Interview>
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button size="m" use="base" onClick={handleNext}>
          次へ
        </Button>
      </Modal.Footer>
    </Box>
  );
});

SelectQuantityContent.displayName = 'SelectQuantityContent';
