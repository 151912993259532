import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { rgba } from 'polished';
import React, { useCallback, useMemo } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { DateLabel, Flex, Icon, Name, Table, Tag, Text } from '~/components/blocks';
import {
  AppointmentStatusLabel,
  DeliveryTag,
  isDeliveryTag,
  isMedicatoinFollowupTag,
  MedicatoinFollowupTag,
  ReceptionTypeIcon,
} from '~/components/partials';
import { ClinicsAppTag } from '~/components/partials/ClinicsAppTag';
import { DeliveryMethodField } from '~/components/partials/DeliveryMethodField';
import { AppointmentDeliveryMethod, ReceptionListAppointmentFragment } from '~/graphql';
import { useUberOrganizationSetting } from '~/hooks/use-uber_organization_setting';
import { profileDrawerState } from '~/state/partials/patient_profile_drawer/atoms';
import {
  checkinEntryProfileDrawerState,
  draftAppointmentPatientProfileDrawerState,
  guestPatientProfileDrawerState,
  newReceptionDrawerState,
  receptionPageInfoState,
  webVisitorProfileDrawerState,
} from '~/state/reception/atoms';
import { Label } from '~/utils/label';

type Props = {
  appointment: ReceptionListAppointmentFragment;
  updated: boolean;
  onAnimationEnd: (appointmentId: string) => void;
  activeMedixs: boolean;
};

const formatAppointmentData = (appointment: ReceptionListAppointmentFragment) => {
  const patient = appointment.patient;
  return {
    id: appointment.id,
    receptAt: appointment.createdAt,
    status: appointment.status,
    patientId: patient?.id,
    familyName: patient?.familyName,
    givenName: patient?.givenName,
    phoneticFamilyName: patient?.phoneticFamilyName,
    phoneticGivenName: patient?.phoneticGivenName,
    birthDate: patient?.birthDate,
    sex: patient?.sex,
    start: appointment.start,
    end: appointment.end,
    memo: appointment.description,
    telemedicine: appointment.telemedicine,
    immediate: appointment.immediate,
    medixsReceptionId: appointment.medixsReception?.receptionId,
    deliveryMethod: appointment.deliveryMethod,
    uberDelivery: appointment.uberDelivery,
  };
};

const animation = keyframes`
    from {
        opacity: 1;
    }
    50% {
        opacity: 0.6;
    }
    to {
        opacity: 1;
    }
`;

const TableRow = styled(Table.Tr, { shouldForwardProp })<{ updated: boolean }>(
  ({ theme, updated }) =>
    css({
      animation: updated ? `${animation} 1s ease 1` : 'none',
      '&:hover': {
        backgroundColor: rgba(theme.colors.background.default, 0.5),
        boxShadow: `inset 3px 0 0 ${theme.colors.background.primary}`,
      },
    }),
);

export const Appointment = React.memo((props: Props) => {
  const { onAnimationEnd } = props;
  const { date } = useRecoilValue(receptionPageInfoState);
  const { enable: enabledUber } = useUberOrganizationSetting();

  const appointment = formatAppointmentData(props.appointment);
  const handleClick = useRecoilCallback(
    ({ set, reset }) =>
      () => {
        set(profileDrawerState, {
          isOpen: true,
          patientId: appointment.patientId as string,
          appointmentId: appointment.id,
          error: null,
        });
        reset(draftAppointmentPatientProfileDrawerState);
        reset(guestPatientProfileDrawerState);
        reset(newReceptionDrawerState);
        reset(webVisitorProfileDrawerState);
        reset(checkinEntryProfileDrawerState);
      },
    [appointment.id, appointment.patientId],
  );
  const memo = useMemo(() => appointment.memo.split(',').filter(Boolean), [appointment.memo]);
  const handleAnimationEnd = useCallback(
    () => onAnimationEnd(props.appointment.id),
    [onAnimationEnd, props.appointment.id],
  );

  return (
    <TableRow updated={props.updated} onClick={handleClick} onAnimationEnd={handleAnimationEnd}>
      <Table.Td nowrap>
        <Flex alignItems="center">
          {appointment.start && appointment.end && (
            <DateLabel>
              {!date && <DateLabel.Time>{Label.YYYYMMDDja(appointment.start)}</DateLabel.Time>}
              <DateLabel.Day>
                {Label.HHMM(appointment.start)}-{Label.HHMM(appointment.end)}
              </DateLabel.Day>
            </DateLabel>
          )}
          {appointment.immediate && <Icon icon="timer" color="blue" size="l" />}
        </Flex>
      </Table.Td>
      <Table.Td>
        <AppointmentStatusLabel
          status={props.appointment.status}
          uberDelivery={appointment.uberDelivery}
          isSameDayDelivery={
            appointment.deliveryMethod === AppointmentDeliveryMethod.SameDayDelivery
          }
        />
      </Table.Td>
      {props.activeMedixs && (
        <Table.Td nowrap>
          {appointment.medixsReceptionId || (
            <Text color="navy" size="s">
              未連携
            </Text>
          )}
        </Table.Td>
      )}
      <Table.Td nowrap>
        <Name
          familyName={appointment.familyName}
          givenName={appointment.givenName}
          phoneticFamilyName={appointment.phoneticFamilyName}
          phoneticGivenName={appointment.phoneticGivenName}
        />
      </Table.Td>
      <Table.Td nowrap>
        <Text size="m" whiteSpace="nowrap">
          {appointment.birthDate ? Label.age(appointment.birthDate) : ''}
        </Text>
        <Text size="s" whiteSpace="nowrap">
          （{appointment.sex ? Label.sex(appointment.sex) : ''}）
        </Text>
      </Table.Td>
      <Table.Td>
        {appointment.telemedicine ? (
          <Flex alignItems="center">
            <ReceptionTypeIcon telemedicine={appointment.telemedicine} />
            <Text fontWeight="bold" size="m" whiteSpace="nowrap">
              オンライン
            </Text>
          </Flex>
        ) : (
          <Flex alignItems="center">
            <ReceptionTypeIcon telemedicine={appointment.telemedicine} />
            <Text fontWeight="bold" size="m" whiteSpace="nowrap">
              対面
            </Text>
          </Flex>
        )}
      </Table.Td>
      <Table.Td>
        <DeliveryMethodField deliveryMethod={appointment.deliveryMethod} size="m" />
      </Table.Td>
      {enabledUber && (
        <Table.Td>
          <Text size="m" whiteSpace="nowrap">
            {appointment.deliveryMethod !== AppointmentDeliveryMethod.SameDayDelivery
              ? '-'
              : appointment.uberDelivery?.orderNumber || '未確定'}
          </Text>
        </Table.Td>
      )}
      <Table.Td>
        <Text size="m" whiteSpace="nowrap">
          {Label.YYYYMMDDja(appointment.receptAt)}&nbsp;{Label.HHMM(appointment.receptAt)}
        </Text>
      </Table.Td>
      <Table.Td>
        <ClinicsAppTag />
        {memo.map((memo, idx) =>
          isDeliveryTag(memo) ? (
            <DeliveryTag key={idx} text={memo} />
          ) : isMedicatoinFollowupTag(memo) ? (
            <MedicatoinFollowupTag key={idx} text={memo} />
          ) : (
            <Tag key={idx} whiteSpace="nowrap">
              {memo}
            </Tag>
          ),
        )}
      </Table.Td>
    </TableRow>
  );
});

Appointment.displayName = 'Appointment';
