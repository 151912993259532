import { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { UberQuoteErrorCode, useGetUberQuoteLazyQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';
import { changeDeliveryMethodState } from '~/state/partials/change_delivery_method_modal/atoms';
import { requestUberDeliveryModalState } from '~/state/reception/atoms';

export const useFetchUberQuote = () => {
  const [state, setState] = useRecoilState(requestUberDeliveryModalState);
  const setChangeDeliveryMethodModal = useSetRecoilState(changeDeliveryMethodState);
  const [getQuote, { loading, error }] = useGetUberQuoteLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const appointment = getNode(data, 'Appointment') || null;
      const uberQuote = appointment?.uberQuote;

      if (uberQuote?.quoteId) {
        setState({ ...state, quoteId: uberQuote.quoteId });
      }

      if (appointment && uberQuote?.errorCode && !uberQuote.quoteId) {
        // 配達エリア圏外の時
        if (uberQuote.errorCode === UberQuoteErrorCode.AddressUndeliverable) {
          setState({
            ...state,
            isOpen: false,
          });
          setChangeDeliveryMethodModal({
            ...state,
            isOpen: true,
            appointmentId: appointment.id,
          });
        }
        // 他のエラーの時
        else {
          setState({
            ...state,
            error: uberQuote.errorCode,
          });
        }
      }
    },
    onError: (error) => {
      setState({
        ...state,
        graphqlError: error.message,
      });
    },
  });

  useEffect(() => {
    if (state.appointmentId) {
      getQuote({ variables: { id: state.appointmentId } });
    }
  }, [state.appointmentId, getQuote]);

  return { loading, error };
};
