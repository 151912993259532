import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { Alert, Box, Grid, Icon, ScrollBox, Table } from '~/components/blocks';
import { CheckinEntry } from '~/components/pages/Reception/ReceptionList/CheckinEntry';
import { WebAppointment } from '~/components/pages/Reception/ReceptionList/WebAppointment';
import { useMedixsSetting } from '~/hooks/use-medixs-setting';
import { useUberOrganizationSetting } from '~/hooks/use-uber_organization_setting';
import { receptionPageInfoState } from '~/state/reception/atoms';

import { Appointment } from './Appointment';
import { DraftAppointment } from './DraftAppointment';
import { ReceptionPagination } from './ReceptionPagination';
import { useFetchReceptions } from './use-fetch-receptions';

const Sort = styled('span')(() =>
  css({
    cursor: 'pointer',
  }),
);

const AscIcon = styled(Icon)(({ theme }) =>
  css({
    color: theme.colors.colorPallete.grey01,
  }),
);

const DescIcon = styled(Icon)(({ theme }) =>
  css({
    color: theme.colors.colorPallete.grey01,
  }),
);

export const ReceptionList = () => {
  const theme = useTheme();

  const { enabledMedixs } = useMedixsSetting();
  const {
    visibleLoading,
    scrollRef,
    updatedAppointmentIds,
    updatedCheckinEntryIds,
    updatedDraftAppointmentIds,
    receptions,
    handleAnimationEnd,
  } = useFetchReceptions();
  const [pageInfo, setPageInfo] = useRecoilState(receptionPageInfoState);
  const { enable: enabledUber, loading } = useUberOrganizationSetting();

  const handleClickCreationTime = useCallback(
    (direction: 'asc' | 'desc') =>
      setPageInfo((_pageInfo) => ({
        ..._pageInfo,
        orderBy: { field: 'created_at', direction },
      })),
    [setPageInfo],
  );
  const handleClickRequestTime = useCallback(
    (direction: 'asc' | 'desc') =>
      setPageInfo((_pageInfo) => ({
        ..._pageInfo,
        orderBy: { field: 'start', direction },
      })),
    [setPageInfo],
  );

  return (
    <Grid
      gridTemplateRows="1fr min-content"
      gridTemplateColumns="1fr"
      mt={theme.space.xl}
      overflow="auto"
    >
      <ScrollBox ref={scrollRef} loading={visibleLoading || loading}>
        <Table appearance="noline">
          <Table.Thead>
            <Table.Tr>
              <Table.Th>
                希望時間
                {pageInfo.orderBy?.field === 'start' && pageInfo.orderBy.direction === 'asc' ? (
                  <Sort onClick={() => handleClickRequestTime('desc')}>
                    <DescIcon size="m" icon="arrow-down" />
                  </Sort>
                ) : (
                  <Sort onClick={() => handleClickRequestTime('asc')}>
                    <AscIcon size="m" icon="arrow-top" />
                  </Sort>
                )}
              </Table.Th>
              <Table.Th>ステータス</Table.Th>
              {enabledMedixs && <Table.Th nowrap>受付ID</Table.Th>}
              <Table.Th nowrap>患者名</Table.Th>
              <Table.Th nowrap></Table.Th>
              <Table.Th width="8%">服薬指導方法</Table.Th>
              <Table.Th>受け渡し方法</Table.Th>
              {enabledUber && <Table.Th width="8%">注文番号</Table.Th>}
              <Table.Th>
                申し込み日時
                {pageInfo.orderBy?.field === 'created_at' &&
                pageInfo.orderBy.direction === 'asc' ? (
                  <Sort onClick={() => handleClickCreationTime('desc')}>
                    <DescIcon size="m" icon="arrow-down" />
                  </Sort>
                ) : (
                  <Sort onClick={() => handleClickCreationTime('asc')}>
                    <AscIcon size="m" icon="arrow-top" />
                  </Sort>
                )}
              </Table.Th>
              <Table.Th width="24%">メモ</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {receptions.map((recepiton) => {
              if (recepiton.__typename === 'Appointment' && recepiton.patient) {
                return (
                  <Appointment
                    key={recepiton.id}
                    activeMedixs={enabledMedixs}
                    updated={updatedAppointmentIds.includes(recepiton.id)}
                    appointment={recepiton}
                    onAnimationEnd={handleAnimationEnd}
                  />
                );
              } else if (recepiton.__typename === 'Appointment' && recepiton.webVisitor) {
                return (
                  <WebAppointment
                    key={recepiton.id}
                    activeMedixs={enabledMedixs}
                    updated={updatedAppointmentIds.includes(recepiton.id)}
                    appointment={recepiton}
                    onAnimationEnd={handleAnimationEnd}
                  />
                );
              } else if (recepiton.__typename === 'CheckinEntry') {
                return (
                  <CheckinEntry
                    key={recepiton.id}
                    activeMedixs={enabledMedixs}
                    updated={updatedCheckinEntryIds.includes(recepiton.id)}
                    checkinEntry={recepiton}
                    onAnimationEnd={handleAnimationEnd}
                  />
                );
              } else if (recepiton.__typename === 'DraftAppointment') {
                return (
                  <DraftAppointment
                    key={recepiton.id}
                    activeMedixs={enabledMedixs}
                    updated={updatedDraftAppointmentIds.includes(recepiton.id)}
                    draftAppointment={recepiton}
                  />
                );
              }
            })}
          </Table.Tbody>
        </Table>
        {!visibleLoading && receptions.length === 0 && (
          <Alert status="info" mt={theme.space.m}>
            条件に一致する患者は見つかりませんでした
          </Alert>
        )}
      </ScrollBox>
      <Box paddingY={theme.space.l}>{receptions.length > 0 && <ReceptionPagination />}</Box>
    </Grid>
  );
};
