import { ApolloError } from '@apollo/client';
import { useTheme } from '@emotion/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { Alert, Box, Button, Checkbox, Flex, Modal, Text } from '~/components/blocks';
import { requestAppointmentModalState } from '~/state/reception/atoms';

import { useRequestAppointment } from './use-request-appointment';

type Props = {
  draftAppointmentId: string;
};

export const RequestAppointmentModal = (props: Props) => {
  const theme = useTheme();

  const [state, setState] = useRecoilState(requestAppointmentModalState);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [error, setError] = useState<ApolloError | undefined>(undefined);

  const handleClose = useCallback(() => setState(() => ({ isOpen: false })), [setState]);
  const handleCheck = useCallback(() => setIsConfirmed((_state) => !_state), []);

  const {
    loading,
    error: requestError,
    handleRequest,
  } = useRequestAppointment({
    draftAppointmentId: props.draftAppointmentId,
  });

  useEffect(() => {
    if (!state.isOpen) {
      setIsConfirmed(false);
      setError(undefined);
    }
  }, [state.isOpen]);

  useEffect(() => {
    setError(requestError);
  }, [requestError]);

  return (
    <Modal open={state.isOpen} size="s" onClose={handleClose}>
      <Modal.Header>処方箋の受理を確認</Modal.Header>
      <Modal.Body>
        {error && <Alert mb={theme.space.l}>エラーが発生したため保存できませんでした</Alert>}
        <Flex alignItems="flex-start" flexDirection="column">
          <Text>この患者の処方箋をFAX等で受理しましたか？</Text>
          <Box marginTop={theme.space.m}>
            <Checkbox label="はい" checked={isConfirmed} onChange={handleCheck} />
          </Box>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Button
          use="secondary"
          disabled={!isConfirmed}
          loading={loading}
          onClick={() => handleRequest()}
        >
          保存
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
